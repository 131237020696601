import type { MultipleQueriesResponse } from '@algolia/client-search'
import { HITS_PER_SHOWCASE } from './algolia/constants'
import type { AlgoliaEventItem } from '~/composables/types/algolia'
export { useAlgoliaIndexNames } from './algolia/useAlgoliaIndexNames'

export const usePromotionEvents = async (
  filters,
  itemsPerPage = HITS_PER_SHOWCASE
) => {
  const searchClient = useAlgoliaRef()
  const { locale } = useI18n()

  const promotionQueryParams = [
    {
      indexName: getEventListIndexName(locale.value),
      facetFilters: [`categories.name:${filters?.category}`],
      filters: '(has_promotions:true)',
      hitsPerPage: itemsPerPage,
      aroundLatLngViaIP: process.client,
      aroundRadius: 'all',
      aroundPrecision: [
        { from: 0, value: 15000 },
        { from: 30000, value: 30000 },
        { from: 150000, value: 100000 },
      ],
      clickAnalytics: true,
    },
  ]

  const {
    data: algoliaResults,
    pending,
    status,
  } = await useLazyAsyncData<MultipleQueriesResponse<AlgoliaEventItem>>(
    `algoliaResults-promoitons-${process.client}`,
    () => {
      return searchClient.search(promotionQueryParams)
    }
  )

  return {
    algoliaResults,
    pending,
    status,
  }
}
